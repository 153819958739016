import { MediaCardCarousel } from './MediaCardCarousel'

const Cards = ({ isVans, dealCount }) => {
  return (
    <section className="section-media-cards">
      <div className="grid__container">
        <div className="media-cards__heading">
          <div className="media-cards__title">
            <p>{dealCount ? <span>{(dealCount / 1000000).toFixed(1)}m</span> : ''}</p>
            <p>Deals available at your finger tips provided from 80+ approved partners</p>
          </div>
          <div className="media-cards__title">
            <p>
              <span>£30m+</span>
            </p>
            <p>
              We&apos;ve saved our users over £30m in the last year compared to buying on a PCP
              contract
              <sup>2</sup>
            </p>
          </div>
        </div>
      </div>
      <MediaCardCarousel isVans={isVans} />
    </section>
  )
}

export default Cards
