import Image from 'next/image'
import useSmoothHorizontalScroll from 'use-smooth-horizontal-scroll'

import { constants } from '../../../lib/constants'
import MediaCard from '../../atomic/MediaCard'

export const MediaCardCarousel = ({ isVans }) => {
  const { scrollContainerRef, handleScroll, scrollTo, isAtStart, isAtEnd } =
    useSmoothHorizontalScroll()

  const cardList = [
    {
      id: 'price-tracker',
      tag: 'Price Tracker',
      title: "We've got you covered.",
      description: "Track the historical price for context on what's genuinely a great lease deal.",
      image: 'tracker-graph.png',
    },
    {
      id: 'locoscore',
      tag: 'LocoScore',
      title: 'Search with confidence.',
      description:
        "Ever wondered if you're getting the best lease deal? Find amazing deals with confidence using our LocoScore.",
      image: 'loco-score.png',
    },
    {
      id: 'price-alerts',
      tag: 'Price Alerts',
      title: 'Be the first to know.',
      description: `Never miss out; Save ${
        isVans ? 'van' : 'car'
      }s to your garage and get price alerts when your favourite ${
        isVans ? 'van' : 'car'
      }s drop in price.`,
      image: 'price-alerts.png',
    },
    {
      id: 'configure',
      tag: `Build your ${isVans ? 'van' : 'car'}`,
      title: `Configure the ${isVans ? 'van' : 'car'} you've always wanted.`,
      description: `An industry first ${
        isVans ? 'van' : 'car'
      } lease configurator. Configure the ${
        isVans ? 'van' : 'car'
      } you've always wanted. You may even find a higher spec ${
        isVans ? 'van' : 'car'
      } for less.`,
      image: 'configure.png',
    },
    {
      id: 'compare',
      tag: 'Compare',
      title: "It's never been easier.",
      description: `We compare so you don't have to. Comparing ${
        isVans ? 'van' : 'car'
      } features has never been easier. Choose the right ${
        isVans ? 'van' : 'car'
      } with confidence.`,
      image: 'compare.png',
    },
    {
      id: 'ethical-code',
      tag: 'Ethical code of conduct',
      title: 'Integrity at our ❤️',
      description:
        'We have the highest advertiser criteria in the industry with all our partners bound by our [Ethical Code of Conduct](/ethical-code-of-conduct).',
      image: 'trusted.png',
    },
  ]

  return (
    <div className="carousel__container">
      <button
        type="button"
        onClick={() => scrollTo(-496)}
        disabled={isAtStart}
        className="slider__button left">
        <Image
          src={`${constants.imageHost.assets}/icons/library/arrow-left.svg`}
          alt=""
          height="20"
          width="20"
        />
      </button>
      <div
        className="deal-slider"
        ref={scrollContainerRef}
        onScroll={handleScroll}>
        {cardList.map(card => (
          <MediaCard
            key={card.id}
            tag={card.tag}
            title={card.title}
            description={card.description}
            image={card.image}
          />
        ))}
      </div>
      <button
        type="button"
        onClick={() => scrollTo(496)}
        disabled={isAtEnd}
        className="slider__button right">
        <Image
          src={`${constants.imageHost.assets}/icons/library/arrow-right.svg`}
          alt=""
          height="20"
          width="20"
        />
      </button>
    </div>
  )
}
