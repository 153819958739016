import Image from 'next/image'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { useMediaQuery } from 'react-responsive'

import { constants } from '../../../lib/constants'
import styles from './media-card.module.scss'

export const MediaCard = ({ tag, title, description, image }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' })

  return (
    <div className={styles.card}>
      <div className={styles.text}>
        <p className={styles.tag}>{tag}</p>
        <h3>{title}</h3>
        <div className={styles.description}>
          <ReactMarkdown>{description}</ReactMarkdown>
        </div>
      </div>
      <div className={styles.image}>
        <Image
          src={`${constants.imageHost.assets}/homepage/${isMobile ? 'mobile-' : ''}${image}`}
          alt={title}
          layout="fill"
        />
      </div>
    </div>
  )
}

MediaCard.propTypes = {
  tag: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
}
